require("./plugins/bootstrap.js");
require("./plugins/magnific-popup.js");
require("./plugins/slick.js");
require("./plugins/bootstrap-datepicker.js");
require("./plugins/jquery.mmenu.min.all.js");

require("./modules/forms.js");
require("./modules/reviews.js");
require("./modules/fastbooker.js");
require("./modules/mobilemenu.js");
require("./modules/sliders.js");
require("./modules/buttons.js");
require("./modules/gallery.js");
